<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      {{props.item.id}}
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <strong>{{props.item.person.first_name || '-'}}</strong><br>
      <span style="color: gray;"> {{props.item.person.name || '-'}} </span>
    </td>
    <td class="justify-center" style="cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <div style="text-align: center;">
        <v-icon>{{props.item.status.icon}}</v-icon><br>
        <strong :style="{color: props.item.status.color}">{{props.item.status.name}}</strong>
      </div>
    </td>
    <td class="justify-center" style="cursor: pointer; text-align: center !important;" @click="goToRouteDetail(props.item.id)">
      <div v-if="props.item.new">
        <span class="business__date business__date--new">Novo</span><br>
      </div>
      <span>{{props.item.date_start_format}}</span>
    </td>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;" @click="goToRouteDetail(props.item.id)">
      <span v-if="props.item.collaborator">{{props.item.collaborator.name || '-'}}</span><br>
      <strong v-if="props.item.category">{{props.item.category.name || '-'}}</strong>
    </td>
    <td class="justify-center" style="padding-top: 4px; cursor: pointer;">
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" >
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="$WiDialog(wiDialogs.events(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>message</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Visualizar Notas</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile @click="$WiDialog(wiDialogs.files(props.item))">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon>attachment</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Arquivos/Anexos</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Businesses',
    data () {
      return {
        wiDialogs: {
          files: (business) => {
            return {
              title: 'Arquivos/Anexos',
              icon: 'attachment',
              component: {
                content: () => import('@/default/component/WiFile/WiFileList'),
                props: {
                  index: this.config.config.name + '-fileList',
                  api: {
                    url: 'sales/sale/file',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Arquivo',
                action: () => {
                  this.$WiDialog({
                    title: 'Arquivos/Anexos ',
                    icon: 'attachment',
                    component: {
                      content: () => import('@/default/component/WiFile/WiFile'),
                      props: {
                        business_id: business.id
                      }
                    },
                    onClose: () => {
                      this.$WiView(this.config.config.name + '-fileList').refresh()
                    }
                  })
                }
              }
            }
          },
          events: (business) => {
            return {
              title: 'Anotações/Eventos ',
              icon: 'message',
              component: {
                content: () => import('@/default/component/WiTimeLine/WiTimeLine'),
                props: {
                  index: this.config.config.name + '-event',
                  api: {
                    url: 'sales/sale/event',
                    params: '?order=id,desc&where=business_id,' + business.id
                  }
                }
              },
              button: {
                icon: 'add',
                text: 'Adicionar Nota',
                action: () => {
                  this.$WiEditDialog({
                    wiConfig: this.config.config.name + '-event',
                    onSubmit: () => {
                      this.$WiView(this.config.config.name + '-event').refresh()
                    },
                    data: {business_id: business.id}
                  })
                }
              }
            }
          }
        }
      }
    },
    methods: {
      goToRouteDetail: function (id) {
        var url = this.config.config.urlDetails
        url.params['id'] = id
        this.$router.push(url)
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
.business__date {
  border-width: 1px;
  border-style: solid;
  padding: 0px 3px;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}
.business__date--new {
  border-color: green;
  background: rgba(0, 128, 0, 0.589);
}
.business__date--expired {
  border-color: red;
  background: rgba(255, 0, 0, 0.507);
}
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
.business__is-template {
  background: orange;
  border-color: rgb(145, 94, 0);
}
</style>